<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{on, attrs}">
      <v-tooltip bottom>
        <template v-slot:activator="{on:on2, attrs: attrs2}">
          <v-btn color="primary" icon v-on="on" v-bind="attrs">
            <v-icon v-on="on2" v-bind="attrs2">mdi-close</v-icon>
          </v-btn>
        </template>
        <span>Refuser la facture</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-toolbar color="primary">
        <v-toolbar-title class="white--text">
          Refuser la facture
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-textarea label="Raison du refus" v-model="raison"></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" :disabled="updating" @click="refusePayment">Refuser</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import dayjs from "dayjs";
import {AuthMixin} from "@/mixins/AuthMixin";
import {ReglementRepository} from "@/repository/reglement";
export default {
  name: 'RefuseReglement',
  props: {
    reglement: Object,
  },
  mixins: [AuthMixin],
  data() {
    return {
      raison: null,
      updating: false,
      dialog: false,
    }
  },
  methods: {
    async refusePayment(){
      this.updating = true;
      let message = "Règlement #" + this.reglement.id.toString();
      if(this.reglement.reference_facture !== null) {
        message+= " de la facture " + this.reglement.reference_facture;
      }
      if(this.reglement.entreprise_name !== null){
        message += " pour l'entreprise " + this.reglement.entreprise_name;
      }
      if(this.reglement.date_reglement !== null){
        message += " à la date de " + dayjs(this.reglement.date_reglement).format("DD/MM/YYYY");
      }
      message += " refusé. Raison : ";
      message += "\n" + this.raison;

      let commentaire = {
        user: this.logged_user.id,
        dossier: this.reglement.dossier_id,
        message: message,
        pinned: true,
      }
      let result = await this.$store.dispatch("dossier/commentaires/postCommentaire", commentaire);
      if (result) {
        this.message = "";
        await this.$store.dispatch("annonce/annonceSuccess", "Facture refusée.");
      }
      let repo = new ReglementRepository();
      await repo.delete(this.reglement);

      this.dialog = false;
      this.updating = false;
      this.$emit("reload");
    },
  }
}
</script>