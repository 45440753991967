<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{on, attrs}">
      <v-tooltip bottom>
        <template v-slot:activator="{on: on2, attrs: attrs2}">
          <v-btn v-on="on" v-bind="attrs" v-if="reglement.status === 'valid'" color="primary" icon>
            <v-icon v-on="on2" v-bind="attrs2">mdi-download</v-icon>
          </v-btn>
        </template>
        <span>Télécharger le fichier XML de banque</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-toolbar color="primary">
        <v-toolbar-title class="white--text">Télécharger le fichier de banque</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-select :items="entreprises" v-model="selectedEntreprise" item-value="id" item-text="name" label="IBAN à sélectionner"></v-select>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" class="white--text"  @click="downloadSingleBankFile" :disabled="downloading">Télécharger</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {ReglementRepository} from "@/repository/reglement";

export default {
  name: 'DownloadReglementFile',
  props: {
    reglement: {}
  },
  async mounted() {
    if (this.$store.getters["entreprises/fetch_status"] === "INITIAL") {
      await this.$store.dispatch("entreprises/fetchEntreprises");
    }
  },
  data() {
    return {
      downloading: false,
      dialog: false,
      selectedEntreprise: null,
    }
  },
  computed: {
    entreprises() {
      let entreprises = this.$store.getters["entreprises/entreprises"];
      return entreprises.filter(entreprise => entreprise.name.indexOf("ARCAVS") !== -1);
    }
  },
  methods: {
    async downloadSingleBankFile() {
      try {
        this.downloading = true;
        let repository = new ReglementRepository();
        await repository.downloadBankFile({reglements:[this.reglement.id], entreprise: this.selectedEntreprise});
      } finally {
        this.downloading = false;
        this.dialog = false;
        this.$emit("reload");
      }
    },
  }
}
</script>